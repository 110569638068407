import React from 'react';
import { DoughnutTile } from 'components';
import styles from '../styles.module.scss';
import { GroupedDataKey } from '../shared/models';

import { getFailedStatusColorsBy, sortFailedStatus } from '../shared/utils';

export interface FailedStatusBySeverityProps {
  showSkeleton?: boolean;
  data: Record<GroupedDataKey, number>;
}

interface ColorBoxProps {
  color: string;
}

const ColorBox = ({ color }: ColorBoxProps) => {
  return (
    <span
      style={{
        color,
        width: '0.75rem',
        height: '0.75rem',
        borderRadius: '0.25rem',
        background: color,
      }}
    />
  );
};

export const FailedStatusBySeverity = React.memo(
  ({ showSkeleton, data }: FailedStatusBySeverityProps) => {
    const { colors } = getFailedStatusColorsBy(data);
    const items = Object.entries(data || {})
      .map(([k, v]) => ({
        value: v,
        name: k,
        color: colors[k],
        legend: {
          label: (
            <span className={styles.legendLabel}>
              <ColorBox color={colors[k]} />
              {k}
            </span>
          ),
          value: v,
        },
      }))
      ?.sort(sortFailedStatus);
    return (
      <DoughnutTile
        showSkeleton={showSkeleton}
        className={styles.tile}
        items={items?.length > 0 ? items : [{ value: 100, color: '#FF0000' }]}
        classes={{ wrapper: styles.doughnutTileWrapper }}
        title="Failed Status by Priority"
      />
    );
  },
);

FailedStatusBySeverity.displayName = 'FailedStatusBySeverity';
