import { Page } from 'components/page';
import React from 'react';
import styles from './styles.module.scss';
import { useRouteError } from 'react-router-dom';
import { getAssetsPath } from 'shared/utils';

interface Error {
  statusText?: string;
  message?: string;
}

export const ErrorContainer = () => {
  return (
    <>
      <img
        className={styles.errorLogo}
        src={getAssetsPath('404')}
        alt="error"
      />
      <h2 className={styles.title}>Page not found!</h2>
      <p className={styles.desc}>
        The page you are looking for may have been moved, deleted, or possibly
        never existed.
      </p>
    </>
  );
};

interface ErrorPageProps {
  usePageProvider?: boolean;
}

export function ErrorPage({ usePageProvider }: ErrorPageProps) {
  const error: Error = useRouteError() as Error;

  // show error message in console
  if (error) {
    console.error(error);
  }

  return (
    <Page
      usePageProvider={usePageProvider}
      pageStyle="summary"
      showHomePageInBreadcrumbs={false}
      className={styles.errorPage}
      title={() => null}
      breadcrumbs={false}
    >
      <ErrorContainer />
    </Page>
  );
}
