import React from 'react';
import { Breadcrumbs } from 'components/breadcrumbs';
import styles from './styles.module.scss';
import cx from 'classnames';
import { useCurrentRoute } from 'hooks';
import { DataRoute, HandleRoute } from 'shared/models';
import { UIMatch } from 'react-router';
import { PageProvider, usePageContext } from './context';
import { PageContainer } from 'page-container';
export * from './context';

interface PageProps {
  className?: string;
  usePageProvider?: boolean;
  breadcrumbs?: boolean;
  showHomePageInBreadcrumbs?: boolean;
  dynamicTitle?: boolean;
  pageStyle?: 'summary' | 'details';
  title?: (
    route?: UIMatch<DataRoute<unknown>, HandleRoute<unknown>>,
  ) => React.ReactNode | false;
}

const DefaultTitle = (route?: UIMatch<unknown, HandleRoute<unknown>>) => {
  if (route?.handle?.title) {
    return <h2 className={styles.titlePage}>{route?.handle?.title}</h2>;
  }
  return <></>;
};

export const Page = ({
  className,
  usePageProvider = true,
  pageStyle = 'summary',
  showHomePageInBreadcrumbs,
  dynamicTitle,
  breadcrumbs = true,
  title = DefaultTitle,
  children,
}: React.PropsWithChildren<PageProps>) => {
  const Body = () =>
    pageStyle === 'summary' ? (
      <PageContainer>
        <PageBody
          className={className}
          showHomePageInBreadcrumbs={showHomePageInBreadcrumbs}
          dynamicTitle={dynamicTitle}
          breadcrumbs={breadcrumbs}
          title={title}
        >
          {children}
        </PageBody>
      </PageContainer>
    ) : (
      <PageContainer>{children}</PageContainer>
    );

  return usePageProvider ? (
    <PageProvider>
      <Body />
    </PageProvider>
  ) : (
    <Body />
  );
};

interface StateProps {}

const PageBody = ({
  className,
  showHomePageInBreadcrumbs,
  dynamicTitle,
  breadcrumbs = true,
  title = DefaultTitle,
  children,
}: React.PropsWithChildren<PageProps>) => {
  const route = useCurrentRoute();
  const { state } = usePageContext<StateProps>();
  const titleFromState = state?.title;

  return (
    <div className={cx(styles.appPage, className)}>
      <div className={cx(styles.header)}>
        <div className={cx(styles.left)}>
          {breadcrumbs && (
            <Breadcrumbs showHomePage={showHomePageInBreadcrumbs} />
          )}
          {titleFromState ? (
            <h2 className={styles.titlePage}>{titleFromState}</h2>
          ) : dynamicTitle ? (
            <h2 className={styles.titlePage}>{``}</h2>
          ) : (
            title(route)
          )}
        </div>
        <div className={cx(styles.right)}>
          {route?.handle?.headerRightTemplate}
        </div>
      </div>
      {children}
    </div>
  );
};
