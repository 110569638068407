import React from 'react';
import { Icon } from 'components/icon';
import styles from './styles.module.scss';

export interface FilterMetaData {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
}

export interface FilterMeta {
  [key: string]: FilterMetaData;
}

interface TagProps {
  label: React.ReactNode;
  value?: React.ReactNode;
  onClear: () => void;
}

const Tag = ({
  label,
  value,
  children,
  onClear,
}: React.PropsWithChildren<TagProps>) => {
  return (
    <div className={styles.tag}>
      <div className={styles.frame}>
        <p className={styles.frameBody}>
          <span className={styles.label}>{label}</span>
          <span className={styles.span}>&nbsp;</span>
          <span className={styles.value}>{value || children}</span>
        </p>
      </div>
      <Icon onClick={onClear} className={styles.close} name="close" />
    </div>
  );
};

interface FilterTagsProps<T> {
  titles: { [key: keyof FilterMeta]: string };
  filters: FilterMeta;
  onClear?: (key: keyof T, value: string) => void;
  onClearAll?: () => void;
}

export function FilterTags<T>({
  onClearAll,
  onClear,
  titles,
  filters,
}: FilterTagsProps<T>) {
  const list = Object.entries(filters);
  const filterList = (list || [])
    .filter(([key]) => key !== 'global')
    ?.map(([key, item], index) => {
      const { value } = (item || {}) as FilterMetaData;
      return (
        value &&
        value?.length > 0 &&
        (value || [])?.map((x: string, i: number) => (
          <Tag
            key={`filter_tag_${index}_${i}`}
            label={`${titles[key]}:`}
            onClear={() => {
              if (typeof onClear === 'function') {
                onClear(key as keyof T, x);
              }
            }}
            value={String(x)}
          />
        ))
      );
    });

  const filteredList = filterList?.flat()?.filter((element) => {
    return element !== null && element !== undefined && element !== false;
  });

  return (
    <div className={styles.filterTags}>
      {filterList}
      {filteredList?.length > 0 && typeof onClearAll === 'function' && (
        <ClearAll onClick={onClearAll} />
      )}
    </div>
  );
}

interface ClearAllProps {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

export const ClearAll = ({ onClick }: ClearAllProps) => {
  return (
    <button onClick={onClick} className={styles.clearAll}>
      <Icon className={styles.close} name="close" />
      <div className={styles.label}>Clear All</div>
    </button>
  );
};
