import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Icon,
  ResultStatus,
  ResultStatusProps,
  usePageContext,
} from 'components';
import { RoutePageData } from 'router/shared/models';
import { useMatch, useNavigate, useParams } from 'react-router-dom';
import { ApiError } from 'shared/api/client';
import { useLoader, useLoaderWithCondinion } from 'hooks';
import { getDashboardDataByCluster } from 'pages/dashboard/shared/utils';
import { DashboardCharts, DashboardView } from 'pages/dashboard/dashboard-view';
import { getDashboard } from 'pages/dashboard/shared/requests';
import { getChecks, getFailures, getReports } from '../shared/requests';
import { useCheckSummary } from 'pages/dashboard/context';
import { SummaryCluster } from 'pages/dashboard/shared/models';
import { DataListView, Data } from './data-list';
import cx from 'classnames';
import styles from '../styles.module.scss';
import { useCurrentCluster } from './context';
import {
  ClusterCheckModel,
  ClusterReportModel,
  FailedCheckModel,
  TileID,
} from '../shared/models';
import { isEqual, toCapitalize } from 'shared/utils';
import { getDataTime, getDate } from 'components/utils';
import { ErrorPage } from 'pages/error-page';
import { countByStatus, getCurrentClusterTiles } from '../shared/utils';
import { FailedStatusBySeverity } from 'pages/dashboard/dashboard-view/failed-status-by-severity.chart';
import { OverallByStatus } from '../../dashboard/dashboard-view/overall-by-status.chart';
import { FilterMeta } from 'components/filter-tags';
import { Calendar } from 'primereact/calendar';

interface StateProps {}
type Params = {
  clusterId: string;
};

export const Cluster = () => {
  const { state, setState, error404 } = usePageContext<StateProps>();
  const paramsUrl = useParams<Params>();
  const [searchValue, setSearchValue] = useState('');
  const clusterId = paramsUrl?.clusterId || '';
  const navigate = useNavigate();
  const [filters, setFilters] = React.useState<FilterMeta>({});
  const {
    date: executionDate,
    clusters,
    setClusters,
    getCluster,
  } = useCheckSummary();

  const {
    failures,
    checks,
    reports,
    view,
    setView,
    setChecks,
    setFailures,
    setReports,
  } = useCurrentCluster();
  const cluster = useMemo(
    () => getCluster(paramsUrl?.clusterId || ''),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [clusters],
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchDataRequest = useCallback(
    getDashboard(getDate((executionDate || new Date())?.toJSON())),
    [paramsUrl, executionDate],
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchDataChecksRequest = useCallback(
    getChecks(getDate((executionDate || new Date())?.toJSON())),
    [paramsUrl, executionDate],
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchDataReportsRequest = useCallback(getReports, [paramsUrl]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchDataFailuresRequest = useCallback(
    getFailures(getDate((executionDate || new Date())?.toJSON())),
    [paramsUrl, executionDate],
  );

  const fetchDataCondition = useMemo(
    () =>
      !cluster?.clusterId ||
      !isEqual(
        cluster?.dateBy,
        getDate((executionDate || new Date())?.toJSON()),
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [paramsUrl, cluster, executionDate],
  );

  const fetchDataRequestErrorHandler = useCallback(
    (e: ApiError) => {
      if (e.status === 404) {
        setState((prev) => ({
          ...prev,
          isLoading: false,
        }));
        error404();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [paramsUrl],
  );

  const { loadedData, isPending } = useLoaderWithCondinion(
    fetchDataRequest,
    fetchDataCondition,
    fetchDataRequestErrorHandler,
  );

  const { loadedData: loadedChecks, isPending: isPendingChecks } = useLoader(
    fetchDataChecksRequest,
  );

  const { loadedData: loadedReports, isPending: isPendingReports } = useLoader(
    fetchDataReportsRequest,
  );

  const { loadedData: loadedFailures, isPending: isPendingFailures } =
    useLoader(fetchDataFailuresRequest);

  useEffect(() => {
    if (paramsUrl?.clusterId && !isPendingReports) {
      const key = paramsUrl?.clusterId;
      setReports((prev) => ({
        ...prev,
        [key]: loadedReports || {},
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadedReports]);

  useEffect(() => {
    if (paramsUrl?.clusterId && !isPendingFailures) {
      const key = paramsUrl?.clusterId;
      setFailures((prev) => ({ ...prev, [key]: loadedFailures || {} }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadedFailures]);

  useEffect(() => {
    if (paramsUrl?.clusterId && !isPendingChecks) {
      const key = paramsUrl?.clusterId;
      setChecks((prev) => ({ ...prev, [key]: loadedChecks || {} }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadedChecks]);

  useEffect(() => {
    if (loadedData && !isPending) {
      setClusters(loadedData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadedData]);

  const onClickReport =
    (id: string) => (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      navigate(`/cluster/${paramsUrl?.clusterId}/reports/${id}`);
    };

  const onClickCheck =
    (opt: ClusterCheckModel) =>
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      const checkName = opt?.name;
      // setSearchValue(checkName);
      setFilters({ title: { value: [checkName] } });

      setView('failures');
    };

  const viewListData = useMemo(() => {
    switch (view) {
      case 'checks': {
        return {
          id: 'checks',
          loading: isPendingChecks,
          list: (Object.values((checks || {})[clusterId] || {}) || []).sort(
            (a, b) => b.count - a.count,
          ),
          header: (
            <div className={styles.dataHeader}>
              <div
                style={{ display: 'flex', flexGrow: 1, paddingLeft: '0.8rem' }}
              >
                Check
              </div>
              <div style={{ display: 'flex', width: '6rem' }}>Status</div>
            </div>
          ),
          searchFields: ['name'],
          itemTemplate: (opt, scollOptions) => {
            return (
              <div
                onClick={opt?.status !== 'PASS' ? onClickCheck(opt) : undefined}
                className={cx(styles.itemList, {
                  [styles.even]: scollOptions.even,
                  [styles.link]: opt?.status !== 'PASS',
                })}
                style={{ height: scollOptions?.props?.itemSize + 'px' }}
              >
                <h6 className={cx(styles.name)}>{opt.name}</h6>
                <div style={{ minWidth: '3.5rem' }} className={styles.data}>
                  <div className={styles.item}>
                    <Icon
                      className={cx(
                        styles.icon,
                        styles[opt?.status?.toLowerCase()],
                      )}
                      size={'1.5rem'}
                      name={opt?.status?.toLowerCase()}
                    />

                    {`${opt?.count || ''}`}
                  </div>
                </div>
              </div>
            );
          },
        } as Data<ClusterCheckModel>;
      }
      case 'reports': {
        return {
          id: 'reports',
          loading: isPendingReports,
          header: (
            <div className={styles.dataHeader}>
              <div
                style={{ display: 'flex', flexGrow: 1, paddingLeft: '0.8rem' }}
              >
                Report Name
              </div>
              <div style={{ display: 'flex', width: '10rem' }}>Date</div>
            </div>
          ),
          list: Object.values((reports || {})[clusterId] || {})?.sort(
            (a, b) => {
              const dateA = new Date(a.date).getTime();
              const dateB = new Date(b.date).getTime();
              return dateB - dateA;
            },
          ),
          searchFields: ['name'],
          itemTemplate: (opt, scollOptions) => {
            return (
              <div
                onClick={onClickReport(opt.id)}
                className={cx(styles.itemList, styles.link, {
                  [styles.even]: scollOptions.even,
                })}
                style={{ height: scollOptions?.props?.itemSize + 'px' }}
              >
                <h6 className={styles.name}>{opt.name}</h6>
                <div className={styles.data}>
                  <div className={styles.item}>{getDataTime(opt?.date)}</div>
                </div>
              </div>
            );
          },
        } as Data<ClusterReportModel>;
      }
      case 'failures': {
        return {
          id: 'failures',
          loading: isPendingFailures,
          list: Object.values((failures || {})[clusterId] || {}),
          itemSize: 85,
          header: (
            <div className={styles.dataHeader}>
              <div
                style={{ display: 'flex', flexGrow: 1, paddingLeft: '0.8rem' }}
              >
                Object/Check
              </div>
              <div style={{ display: 'flex', width: '7rem' }}>Result</div>
            </div>
          ),
          searchFields: ['name', 'title'],
          itemTemplate: (opt, scollOptions) => {
            return (
              <div
                className={cx(styles.itemList, {
                  [styles.even]: scollOptions.even,
                })}
                style={{ height: scollOptions?.props?.itemSize + 'px' }}
              >
                <div className={styles.titles}>
                  <h6 className={styles.name}>{opt.name}</h6>
                  <h6 className={styles.checkName}>{opt.title}</h6>
                </div>
                <div style={{ width: '6rem' }} className={styles.data}>
                  <div className={styles.item}>
                    <ResultStatus
                      type={opt?.status as ResultStatusProps['type']}
                    >
                      {toCapitalize(opt?.status)}
                    </ResultStatus>
                  </div>
                </div>
              </div>
            );
          },
        } as Data<FailedCheckModel>;
      }
      default: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return { list: [], itemTemplate: () => <></> } as any;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [view, checks, reports, failures]);

  const allData = useMemo(
    () => getDashboardDataByCluster((cluster || {}) as SummaryCluster),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cluster],
  );

  useEffect(() => {
    setState((prev) => {
      return {
        ...prev,
        title: allData?.name,
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allData]);

  useEffect(
    () => () => {
      setState((prev) => {
        return {
          ...prev,
          title: undefined,
        };
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const showSkeleton = Object.keys(clusters || {}).length === 0 && isPending;

  const onClickTileHandler = (tile: TileID) => {
    setView('checks');
    setSearchValue('');
    switch (tile) {
      case 'total': {
        setFilters({});
        break;
      }
      case 'pass': {
        setFilters({ status: { value: ['PASS'] } });
        break;
      }
      case 'fail': {
        setFilters({ status: { value: ['FAIL'] } });
        break;
      }
      case 'noResult': {
        setFilters({ status: { value: ['ERROR'] } });
        break;
      }
      default: {
        break;
      }
    }
  };

  const tiles = useMemo(
    () => getCurrentClusterTiles(checks[clusterId] || {}, onClickTileHandler),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [checks[clusterId], onClickTileHandler],
  );

  const onClearHandler = (key: keyof ClusterCheckModel, value: string) => {
    setFilters((prev) => {
      const newState = { ...prev };
      delete newState[key];
      return newState;
    });
  };

  const onClearAllHandler = () => {
    setFilters({});
  };

  if (state?.isError404) {
    return <ErrorPage />;
  }

  const checkList = Object.values((checks || {})[clusterId] || {});

  const totalChecks = checkList.length || 0;

  const counts = countByStatus(checkList);

  return (
    <DashboardView>
      <DashboardCharts
        clusterID={paramsUrl?.clusterId}
        tiles={tiles}
        doughnutsCharts={
          <>
            <OverallByStatus
              showSkeleton={showSkeleton}
              total={totalChecks}
              passed={counts?.PASS}
              failed={counts?.FAIL}
              noResult={counts?.ERROR + counts?.UNKNOWN}
            />
            <FailedStatusBySeverity
              data={allData?.groupedData}
              showSkeleton={showSkeleton}
            />
          </>
        }
        showSkeleton={showSkeleton}
      />
      <DataListView
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        onClear={onClearHandler}
        onClearAll={onClearAllHandler}
        filters={filters}
        data={viewListData || {}}
      />
    </DashboardView>
  );
};

const HeaderRight = () => {
  const { date, setDate } = useCheckSummary();
  return (
    <div className={styles.date}>
      <div>
        <Calendar
          showIcon
          style={{ width: '9rem' }}
          inputClassName={styles.input}
          iconPos={'right'}
          icon={<Icon size={'1.5rem'} name="calendar" />}
          placeholder={'MM/DD/YYYY'}
          value={date}
          onChange={(e) => setDate(e?.value)}
        />
      </div>
    </div>
  );
};

const TitlePage = () => {
  const paramsUrl = useParams<Params>();

  const clusterId = paramsUrl?.clusterId || '';
  const { clusters, getCluster } = useCheckSummary();

  const cluster = useMemo(
    () => getCluster(clusterId),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [clusters],
  );
  const match = useMatch(`/cluster/:clusterId/reports/:executionId`);

  return match ? <>{cluster?.name || 'Cluster'}</> : <>{'Cluster'}</>;
};

export default {
  title: <TitlePage />,
  showHomePageInBreadcrumbs: true,
  dynamicTitle: true,
  headerRightTemplate: <HeaderRight />,
  pageStyle: 'summary',
  route: {
    path: '/cluster/:clusterId',
    Component: Cluster,
  },
} as RoutePageData;
