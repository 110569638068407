import { TileProps } from 'components';
import { CheckStatus } from 'shared/api/client';
import { ClusterCheckModel, TileID } from '../models';

export function countByStatus(arr: ClusterCheckModel[]): {
  [key in CheckStatus]: number;
} {
  const result: { [key in CheckStatus]: number } = {
    UNKNOWN: 0,
    PASS: 0,
    IGNORE: 0,
    FAIL: 0,
    ERROR: 0,
  };

  arr?.forEach((item) => {
    result[item.status]++;
  });

  return result;
}

export const getCurrentClusterTiles = (
  data: Record<string, ClusterCheckModel>,
  onClick: (tile: TileID) => void,
): TileProps[] => {
  const counts = countByStatus(Object.values(data));
  return [
    {
      title: 'Total Checks',
      count: Object.keys(data)?.length || 0,
      onClick: () => onClick('total'),
    },
    {
      title: 'Passed',
      textColor: 'green',
      count: counts?.PASS || 0,
      iconSize: 'small',
      icon: 'pass',
      onClick: () => onClick('pass'),
    },
    {
      title: 'Failed',
      textColor: 'red',
      count: counts?.FAIL || 0,
      iconSize: 'small',
      icon: 'fail',
      onClick: () => onClick('fail'),
    },
    {
      title: 'No Result',
      textColor: 'orange',
      count: counts?.ERROR || 0,
      iconSize: 'small',
      icon: 'error',
      onClick: () => onClick('noResult'),
    },
  ];
};
