import React from 'react';
import { createBrowserRouter, Outlet, RouteObject } from 'react-router-dom';
import { Page as PageComponent } from 'components/page';
import { pages } from './register-pages';
import { RoutePage } from './shared/utils/route-page';

function wrapComponentPage(page: RoutePage): React.ComponentType {
  if (!!page.isOutlet && page.route.Component) {
    return page.route.Component;
  }
  return function WrappedComponent(props) {
    return (
      <PageComponent
        showHomePageInBreadcrumbs={page?.showHomePageInBreadcrumbs}
        dynamicTitle={page?.dynamicTitle}
        pageStyle={page.pageStyle}
      >
        {page.route.Component ? <page.route.Component {...props} /> : null}
      </PageComponent>
    );
  };
}

const mapChildernPages = (page: RoutePage) => {
  return page?.children?.map((x) => mapToPage(x));
};

const mapToPage = (page: RoutePage): RouteObject => {
  const routeProps = page.toRouting();

  const commonProps: RouteObject = {
    ...routeProps,
    children: page.children.length > 0 ? mapChildernPages(page) : undefined,
  } as RouteObject;

  if (!!page.isOutlet && !page.route.Component) {
    return {
      ...commonProps,
      Component: (props) => <Outlet {...props} />,
    } as RouteObject;
  }

  if (page?.route?.element) {
    return {
      ...commonProps,
      element: page.wrapToPage ? (
        <PageComponent
          showHomePageInBreadcrumbs={page?.showHomePageInBreadcrumbs}
          dynamicTitle={page?.dynamicTitle}
          pageStyle={page.pageStyle}
        >
          {page?.route?.element}
        </PageComponent>
      ) : (
        page?.route?.element
      ),
    } as RouteObject;
  }

  if (page.route.Component) {
    return {
      ...commonProps,
      Component: page.wrapToPage
        ? wrapComponentPage(page)
        : page.route.Component,
    } as RouteObject;
  }
  return routeProps;
};
const routes = pages.map((x) => mapToPage(x));
const router = createBrowserRouter(routes);

export default router;
