import React from 'react';
import { RouterProvider } from 'react-router-dom';
import router from './router';
import { APIOptions, PrimeReactProvider } from 'primereact/api';
import { DialogProvider } from 'shared/providers/dialog';
import { ToastProvider } from 'shared/providers/toast';
import { CurrentReportProvider } from 'pages/reports/current-report/context/provider';
import { CheckSummaryProvider } from 'pages/dashboard/context/provider';
import { CurrentClusterProvider } from 'pages/check-summary/current-cluster/context/provider';

function App() {
  const value: Partial<APIOptions> = {
    inputStyle: 'filled',
    ripple: true,
  };

  return (
    <PrimeReactProvider value={value}>
      <ToastProvider>
        <DialogProvider>
          <CheckSummaryProvider>
            <CurrentClusterProvider>
              <CurrentReportProvider>
                <RouterProvider
                  router={router}
                  future={{ v7_startTransition: true }}
                />
              </CurrentReportProvider>
            </CurrentClusterProvider>
          </CheckSummaryProvider>
        </DialogProvider>
      </ToastProvider>
    </PrimeReactProvider>
  );
}

export default App;
