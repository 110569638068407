import { PageManager, RoutePage } from './shared/utils';
import HomePage from 'pages/dashboard';
import ClusterPage from 'pages/check-summary/current-cluster';
import ReportPage from 'pages/reports/current-report';

export const PAGE_MANAGER = new PageManager(false, {
  root: {
    index: HomePage,
    cluster: {
      index: ClusterPage,
      report: ReportPage({
        prefix: `${ClusterPage?.route?.path}/reports`,
        showHomePageInBreadcrumbs: true,
      }),
    },
  },
});

export const pages: RoutePage[] = PAGE_MANAGER.getPages();
