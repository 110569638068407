import React from 'react';
import { DoughnutTile } from 'components';
import styles from '../styles.module.scss';
import { formatNumber } from 'shared/utils';

interface ClusterReportingStatusProps {
  showSkeleton?: boolean;
  total: number;
  reportsReceived: number;
  reportsMissing: number;
  reportsPartial: number;
}

export const ClusterReportingStatus = React.memo(
  ({
    showSkeleton,
    total,
    reportsReceived,
    reportsMissing,
    reportsPartial,
  }: ClusterReportingStatusProps) => {
    const reportsReceivedPrecent = (reportsReceived / total) * 100 || 0;
    const reportsMissingPrecent = (reportsMissing / total) * 100 || 0;
    const reportsPartialPrecent = (reportsPartial / total) * 100 || 0;

    const items = [
      {
        value: reportsReceivedPrecent,
        color: '#57AE60',
        legend: {
          label: <span className={styles.legendLabel}>Reports received</span>,
          value: `${formatNumber(reportsReceivedPrecent)}%`,
        },
      },
      {
        value: reportsMissingPrecent,
        color: '#F2994A',
        legend: {
          label: <span className={styles.legendLabel}>Reports missing</span>,
          value: `${formatNumber(reportsMissingPrecent)}%`,
        },
      },
      {
        value: reportsPartialPrecent,
        color: '#E84F40',
        legend: {
          label: <span className={styles.legendLabel}>Partial reports</span>,
          value: `${formatNumber(reportsPartialPrecent)}%`,
        },
      },
    ];

    return (
      <DoughnutTile
        className={styles.tile}
        showSkeleton={showSkeleton}
        items={items}
        classes={{ wrapper: styles.doughnutTileWrapper }}
        title="Cluster Reporting Status"
      />
    );
  },
);

ClusterReportingStatus.displayName = 'ClusterReportingStatus';
